<script>
import i18n from "../i18n";
import axios from "axios";
import { authHeader } from "../helpers/auth-header";


export default {
    data() {
        return {
            languages: [
                {
                    // flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: "English"
                },
                {
                    // flag: require("@/assets/images/flags/french.jpg"),
                    language: "fr",
                    title: "French"
                },
                {
                    // flag: require("@/assets/images/flags/spain.jpg"),
                    language: "es",
                    title: "spanish"
                },
                {
                    // flag: require("@/assets/images/flags/chaina.png"),
                    language: "zh",
                    title: "Chinese"
                },
                {
                    // flag: require("@/`assets/images/flags/arabic.png"),
                    language: "ar",
                    title: "Arabic"
                }
            ],
            current_language: "en",
            profileName: '',
        };
    },
    components: {  },
    mounted() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.name) {
            this.profileName = user.name;
        }
    },
    methods: {
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        setLanguage(locale) {
            i18n.locale = locale;
            this.current_language = i18n.locale;
        },
        async logout() {
            try {
                const userData = localStorage.getItem('user');
                console.log(`${process.env.VUE_APP_APIURL}/admin/logout`);
                const item = await axios.post(`${process.env.VUE_APP_APIURL}/admin/logout`, userData,  {
                    headers: authHeader(),
                });
                console.log(item);

                const result = item.data;
                if (result['code'] === 'success') {
                    console.log('asdasd');
                    localStorage.clear(); // temp solve logout, not sure if this is good
                    sessionStorage.clear();
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="@/assets/images/logo.png" alt="Logo" height="22" />
                        </span>
                        <span class="logo-lg">
                            <div class="d-flex align-items-center" style="gap: 15px;">
                                <img src="@/assets/images/logo.png" alt="Logo" height="45" />
                                <img src="@/assets/images/special_minds.png" height="25" alt="Special Minds" />
                            </div>
                        </span>
                    </a>
                </div>
                <button @click="toggleMenu" type="button" class="btn btn-sm px-3 font-size-24 header-item waves-effect"
                    id="vertical-menu-btn">
                    <i class="ri-menu-2-line align-middle"></i>
                </button>
            </div>
            <div class="d-flex">
                <b-dropdown right variant="black" toggle-class="header-item" class="d-inline-block user-dropdown">
                    <template v-slot:button-content>
                        <span class="d-xl-inline-block ml-1">{{ profileName }}</span>
                        <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
                    </template>
                    <!-- item-->
                    <a class="dropdown-item" href="/profile">
                        <i class="ri-user-line align-middle mr-1"></i>
                        {{ $t('navbar.dropdown.kevin.list.profile') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="/logout" >
                        <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
                        {{ $t('navbar.dropdown.kevin.list.logout') }}
                    </a>
                </b-dropdown>
            </div>
        </div>
    </header>
</template>

<style lang="scss" scoped>
.notify-item {
    .active {
        color: #16181b;
        background-color: #f8f9fa;
    }
}
</style>